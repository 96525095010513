<template>
    <div>
        <v-container>
            <notification-popup
                :dialog="notifQRFailed"
                :contentMsg="$t('FailedQrGenerateMsg')"
                :headerMsg="$t('FailedQrGenerateHeader')"
                :nameBtnNo="$t('labelNo')"
                :nameBtnYes="$t('Back')"
                colorIcon="danger"
                typeModal="transaction"
                Icon="mdi-close-circle"
                disableBtnNo="true"
                imgSource="notification/failed.svg"
                :onHandlerYes="buttonBack"
            />
            <notification-popup
                :dialog="notifGetFailed"
                :contentMsg="$t('PleaseTryAgain')"
                :headerMsg="$t('FailedQrGenerateHeader')"
                :nameBtnNo="$t('Close')"
                :nameBtnYes="$t('GenerateQR')"
                colorIcon="danger"
                typeModal="transaction"
                Icon="mdi-close-circle"
                imgSource="notification/failed.svg"
                :onHandlerYes="buttonGenerateTicket"
                :onHandlerNo="buttonClose"
            />
            <v-row class="mx-0 box">
                <v-col cols="12">
                    <h3 class="grey--text font-weight-regular">{{$t("User")}}</h3>
                    <h2 class="break-anywhere">{{name}}</h2>
                </v-col>
            </v-row>
            <v-row class="mx-0 box mt-4" justify="center">
                <v-col cols="12">
                    <qrcode-vue :value="qrCode" :size="150" class="mt-10 mb-0 text-center"/>
                </v-col>
                <v-col class="text-center">
                    <span class="link" @click="loadInitial">{{$t("Refresh")}}</span>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import NotificationPopup from "@/components/NotificationPopup.vue";
export default {
    name:"QrCode",
    components: {
        QrcodeVue,
        NotificationPopup
    },
    data(){
        return {
            name : "",
            qrCode : '',
            notifQRFailed : false,
            notifGetFailed : false,
        }
    },
    methods:{
        generateQrCode(){
            this.$store
            .dispatch("customer/GetQrCode")
            .then((response) => {
                if(response.code=='404'){
                    this.notifQRFailed = true
                }
                this.qrCode = response.qr_code
            })
            .catch((err) => {
                console.log(err);
                this.notifGetFailed = true
            });
        },
        buttonBack(){
            this.$router.go(-1)
        },
        buttonClose(){
            this.notifGetFailed = false
        },
        buttonGenerateTicket(){
            this.loadInitial()
            this.notifGetFailed = false
        },
        loadInitial() {
            this.$store
            .dispatch("customer/detailCustomer")
            .then((response) => {
                this.name = response.fullName;
                this.gender = response.gender
            })
            .catch((err) => {
                console.log(err);
            });
            this.generateQrCode()
        }
    },
    mounted(){
        this.loadInitial()
    }
}
</script>

<style lang="scss" scoped>
.box {
    background: #FFFFFF;
    border: 1px solid #4BB14E;
    border-radius: 10px;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 10%), 0px 4px 8px rgb(0 0 0 / 5%);
}
.link{
    color: #4BB14E;
    text-decoration: underline;
}
</style>>